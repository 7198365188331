import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useSearchParams } from "react-router-dom";

import { getDefaultBrand, getDefaultTeam } from "../../helpers/Helpers";

export interface FeatureState {
  page: number;
  searchQuery: string;
  pageSize: number;
  team: number;
  brand: number | 0;
  hasLiveRules: boolean;
  archived: boolean;
  debouncedSearch: string;
}

interface FeatureContextType {
  filters: FeatureState;
  setFilters: (filters: Partial<FeatureState>) => void;
  handleFilterChange: (filters: Partial<FeatureState>) => void;
}

export const FeatureContext = createContext<FeatureContextType | undefined>(
  undefined
);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersState = {
    page: Number(searchParams.get("page")) || 1,
    pageSize: Number(searchParams.get("pageSize")) || 25,
    hasLiveRules: searchParams.get("hasLiveRules") === "true" ? true : false,
    archived: searchParams.get("archived") === "true" ? true : false,
    searchQuery: searchParams.get("searchQuery") || "",
    team:
      Number(searchParams.get("team")) !== null
        ? Number(searchParams.get("team"))
        : getDefaultTeam(),
    brand:
      Number(searchParams.get("brand")) !== 0
        ? Number(searchParams.get("brand"))
        : getDefaultBrand(),
    debouncedSearch: "",
  };

  const [filters, setFiltersState] = useState<FeatureState>(filtersState);

  useEffect(() => {
    const queryFilters: Partial<FeatureState> = filtersState;

    setFiltersState((prev) => ({ ...prev, ...queryFilters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const setFilters = useCallback((newFilters: Partial<FeatureState>) => {
    setFiltersState((prev) => ({ ...prev, ...newFilters }));
  }, []);

  const handleFilterChange = useCallback(
    (newFilter: Partial<FeatureState>) => {
      const updatedFilters = { ...filters, ...newFilter };
      setFilters(updatedFilters);
      const searchParamsObject: Record<string, string> = {};
      Object.keys(updatedFilters).forEach((key) => {
        searchParamsObject[key] = String(
          updatedFilters[key as keyof typeof updatedFilters]
        );
      });
      setSearchParams(searchParamsObject);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    [filters, setFilters, setSearchParams]
  );

  const value = useMemo(
    () => ({
      filters,
      setFilters,
      handleFilterChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, setFilters]
  );

  return (
    <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FeatureContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FilterProvider");
  }
  return context;
};
