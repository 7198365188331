import React, { useState, useEffect } from "react";

import {
  Typography,
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SearchField from "../components/common/Searchfield";
import { useFilters } from "./context/FilterContext";
import NewFlag from "./NewFlag";
import { useConfig } from "./../context";

import { useTitle } from "../helpers/useTitle";
import { interaction } from "../Tracking";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
      width: 250,
      marginLeft: 50,
    },
  },
};

export const FilterToolbar: React.FC<any> = () => {
  const { filters, handleFilterChange } = useFilters();
  const { searchQuery, team, brand, hasLiveRules, archived } = filters;

  const [managedTeams, setManagedTeams] = useState<
    { id: number; name: string; team_key: string }[]
  >([]);

  const { config } = useConfig();

  const [selectedTeam, setSelectedTeam] = useState({
    id: 0,
    name: "All",
    team_key: "all",
  });

  (window as any).selectedTeam = selectedTeam;

  const [searchTrackingTimeout, setSearchTrackingTimeout] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (config.teams) {
      const mergedTeams = [
        { id: 0, name: "All", team_key: "all" },
        ...config.teams,
      ];
      setManagedTeams(mergedTeams);
    }
  }, [config.teams]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange({
      ...filters,
      page: 1,
      searchQuery: event.target.value,
    });

    if (event.target.value.length > 3) {
      const timeout = setTimeout(() => {
        interaction("search_features", [
          {
            name: "search_term",
            stringValue: event.target.value,
          },
        ]);
      }, 1800);

      setSearchTrackingTimeout(timeout);
    }
  };

  useEffect(() => {
    return () => {
      if (searchTrackingTimeout) {
        clearTimeout(searchTrackingTimeout);
      }
    };
  }, [searchTrackingTimeout]);

  const handleTeamChange = (_: any, value: any) => {
    const id = value ? value.id : 0;
    handleFilterChange({
      ...filters,
      team: id,
      page: 1,
      brand: id,
    });
    interaction("select_metric", [
      { name: "team", stringValue: value?.name || "all" },
    ]);
  };

  const handleHasLiveRulesChange = (event: any, value: any) => {
    handleFilterChange({
      ...filters,
      hasLiveRules: event.target.checked,
      page: 1,
    });
    interaction("select_metric", [
      {
        name: "filter_live",
        stringValue: event.target.checked ? "true" : "false",
      },
    ]);
  };

  const handleBrandChange = (event: any) => {
    handleFilterChange({
      ...filters,
      brand: event.target.value > 0 ? event.target.value : 0,
      page: 1,
    });
    interaction("select_metric", [
      {
        name: "filter_live",
        stringValue: event.target.checked ? "true" : "false",
      },
    ]);
  };

  const toggleDeleted = (event: any) => {
    handleFilterChange({
      ...filters,
      archived: event.target.checked,
      page: 1,
    });
  };

  useTitle("Features");

  useEffect(() => {
    if (filters.team && typeof filters.team === "number") {
      const foundTeam = managedTeams.find((i: any) => i.id === filters.team);
      setSelectedTeam(foundTeam || { id: 0, name: "All", team_key: "all" });
    } else {
      setSelectedTeam({ id: 0, name: "All", team_key: "all" });
    }
  }, [filters.team, managedTeams]);

  return (
    <Box>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid md={3} xs={6}>
          <SearchField
            value={searchQuery}
            handleSearchChange={handleInputChange}
          />
        </Grid>
        <Grid md={2} xs={6}>
          <FormControl
            sx={{ m: 1, width: "98%" }}
            size="small"
            className={brand > 0 ? "active-filter" : ""}
          >
            <InputLabel id="brand-select-label">Brand</InputLabel>
            <Select
              value={config?.brands?.length > 0 ? brand : ""}
              onChange={handleBrandChange}
              labelId="brand-select-label"
              id="brand-select"
              label="Brand"
              MenuProps={MenuProps}
            >
              <MenuItem value="0">All</MenuItem>
              {config?.brands?.map((item: { name: string; id: number }) => {
                return (
                  <MenuItem key={item.name} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid md={2} xs={3}>
          <Autocomplete
            id="demo-simple-select"
            onChange={handleTeamChange}
            value={selectedTeam}
            disablePortal
            options={managedTeams}
            getOptionLabel={(option: any) => {
              const team = managedTeams.find((i: any) => i.id === option.id);
              return team ? team.name : "";
            }}
            sx={{ m: 1, width: "98%" }}
            size="small"
            className={team > 0 ? "active-filter" : ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Teams"
                placeholder="All teams"
              />
            )}
            isOptionEqualToValue={(option, selectedValue) => {
              return option?.id === selectedValue?.id;
            }}
          />
        </Grid>
        <Grid md={2} xs={3}>
          <FormControl sx={{ mt: "-10px" }}>
            <FormControlLabel
              value="top"
              control={
                <Switch
                  size="small"
                  checked={hasLiveRules}
                  onChange={handleHasLiveRulesChange}
                  color="success"
                  sx={{ mt: "-10px" }}
                />
              }
              label={
                <Typography sx={{ paddingBottom: 1 }} variant="caption">
                  Live
                </Typography>
              }
              labelPlacement="top"
            />
          </FormControl>
          <FormControl
            sx={{
              mt: "-10px",
              display: config.user.level === 100 ? "inline-flex" : "none",
            }}
          >
            <FormControlLabel
              value="top"
              control={
                <Switch
                  size="small"
                  checked={archived}
                  onChange={toggleDeleted}
                  color="success"
                  sx={{ mt: "-10px" }}
                />
              }
              label={
                <Typography sx={{ paddingBottom: 1 }} variant="caption">
                  Deleted
                </Typography>
              }
              labelPlacement="top"
            />
          </FormControl>
        </Grid>
        <Grid xs="auto" sx={{ marginLeft: "auto" }}>
          {config?.user?.level > 30 ? <NewFlag /> : ""}
        </Grid>
      </Grid>
    </Box>
  );
};
