import React from "react";

import { Typography, Paper, Grid, Container, Link, Box } from "@mui/material";
import logo from "../../assets/fabulous.png";
import { useConfig } from "../../context";

const MaintenanceModeWrapper: React.FC<{}> = (props) => {
  const { config }: any = useConfig();

  return (
    <>
      {config && config.maintenance_mode ? (
        <Container>
          <Paper sx={{ p: 2, mt: 10 }}>
            <Grid container justifyContent="space-around">
              <Grid item xs={10} container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    component="img"
                    sx={{ height: 54 }}
                    alt="Logo"
                    src={logo}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Maintenance Mode
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Fabulous is currently in maintenance mode. Please try again
                    later.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    If you need help, please contact{" "}
                    <Link href="mailto:experimentation-support@hm.com">
                      experimentation-support@hm.com
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      ) : (
        props.children
      )}
    </>
  );
};

export default MaintenanceModeWrapper;
